import { defineStore } from 'pinia'
import { Session } from '@/types/chat'
import { useAxios } from '@/utilities/useAxios'
import { downloadFileFromBackend } from '@/utilities/download_file'

interface SessionExistResponse {
  session_found: boolean
}

export const useSessionStore = defineStore('session', {
  state: () => ({
    session_id: '' as string,
    sessionsList: [] as Session[]
  }),
  getters: {
    sessionCount: (state) => state.sessionsList.length,
    currentSession: (state) =>
      state.sessionsList.find((session) => (session?.session_id || '') == state.session_id)
  },
  actions: {
    goToSession(value: string) {
      this.session_id = value
    },
    async getSessionList(page: number = 1, mode: "incremental" | "overwrite" = "overwrite") {
      /**
       * getSessionList is a action to fetch list of sessions from backend api
       */

      const { instanceApiV2: axiosInstanceAPIV2 } = useAxios()
      const response = await axiosInstanceAPIV2.get('chat/get_session_list', {
        params: {
          page: page,
          per_page: 20,
        }
      })

      if(mode === "incremental"){
        this.sessionsList = [...this.sessionsList, ...response.data.sessions]
      }
      else{
        this.sessionsList = response.data.sessions
      }
    },

    async createSession() {
      const { instance: axiosInstance } = useAxios()

      try {
        // make mock loading

        const response = await axiosInstance.post('chat/create_session')

        this.session_id = response.data.session_id
      } catch (error) {}
    },

    async createNameSession(sessionId: string | number) {
      const { instance: axiosInstance } = useAxios()

      try {
        await axiosInstance.post('chat/update_session_name', {
          session_id: sessionId
        })
      } catch (error) {
        throw error
      }
    },

    async deleteSession(session_id: number): Promise<string | true> {
      /**
       * deleteSession is a action to call delete session api from backend
       * and remove target session from sessionList
       */
      const { instance: axiosInstance } = useAxios()

      try {
        // call api
        const response = await axiosInstance.post('chat/delete_session', {
          session_id: session_id
        })

        const index = this.sessionsList.findIndex((session) => session.session_id === session_id)
        if (index !== -1) {
          this.sessionsList.splice(index, 1)
        }
        return true
      } catch (error) {
        throw error
      }
    },
    async checkChatExists(session_id: string): Promise<string | boolean> {
      const { instance: axiosInstance } = useAxios()
      try {
        const response = await axiosInstance.post('chat/is_session_deleted', {
          session_id: session_id
        })

        return (response.data as SessionExistResponse).session_found
      } catch (error) {
        console.error('Error fetching user role:', error)
        return false
      }
    },
    async *getSessionFiles(session_id: string): AsyncGenerator<DataManagementFile[]> {
      const { instance: axiosInstance } = useAxios()
      try {
        const response = await axiosInstance.get(`data_management/session/${session_id}/list`)
        const responseData = response.data as DataManagementFile[]

        // set session files first time (allow ui to render without wait image files download process)
        yield [...responseData]
        // fetch files from the server
        for (const [index, fileRecord] of responseData.entries()) {
          if (fileRecord.mime_type.startsWith('image/')) {
            responseData[index].image = (await downloadFileFromBackend(
              fileRecord.id,
              'original',
              'view'
            )) as string
          }
        }
        yield [...responseData]
      } catch (error) {
        throw error
      }
    },
    async deleteSessionFile(session_id: string, file_id: number): Promise<DataManagementFile[]> {
      const { instance: axiosInstance } = useAxios()
      try {
        const response = await axiosInstance.post(
          `data_management/session/${session_id}/file/${file_id}/delete`
        )

        return response.data
      } catch (error) {
        console.error('Error delete session files:', error)
        throw error
      }
    }
  }
})
